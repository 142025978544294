﻿.drug-plans {
    background-color: $color-light-green;
    box-shadow: 0 0 12px 0 $color-light-green;
    padding-top: 60px;
    padding-bottom: 80px;

    .drug-plans--steps-col {
        &:last-child {
            .step-icon, .step-icon-arrow-down {
                span {
                    display: none;
                }
            }
        }
    }

    .drug-plans--title {
        margin-bottom: 15px;
        text-align: center;

        @include breakpoint($sm) {
            margin-bottom: 25px;
        }

        h1 {
            max-width: 290px;
            margin: 0 auto;
            font-family: $font-heading-bold;
            font-size: 32px;
            color: $color-text-primary;
            line-height: 42px;

            @include breakpoint($sm) {
                font-size: 37px;
                line-height: 42px;
                max-width: 810px;
            }

            @include breakpoint($md) {
                font-size: 42px;
                line-height: 50px;
            }
        }
    }

    .drug-plans--content {
        text-align: center;
        max-width: 290px;
        margin: 0 auto 20px auto;
        font-size: 16px;
        color: $color-text-secondary;
        line-height: 26px;

        p {
            margin-bottom: 20px;
        }

        @include breakpoint($sm) {
            max-width: 740px;
        }
    }

    .drug-plans--steps {
        text-align: center;

        .step-icon {
            margin: 20px 0;

            img {
                height: 93px;
                width: 90px;
            }

            span {
                display: inline-block;
                @extend .icon;
                @extend .icon-right-arrow;
                vertical-align: middle;
                position: absolute;

                &:before {
                    color: $color-text-primary;
                    font-size: 35px;
                    top: 30px;
                    left: 31px;
                    position: relative;

                    @include breakpoint($md) {
                        left: 51px;
                    }

                    @include breakpoint($xg) {
                        left: 71px;
                    }
                }
            }
        }

        .step-title {
            line-height: 26px;
            max-width: 210px;
            margin: 0 auto;

            &.step-title-invoice {
                max-width: 175px;
            }
        }

        .step-icon-arrow-down {

            span {
                margin-top: 30px;
                margin-bottom: 18px;
                display: inline-block;
                @extend .icon;
                @extend .icon-down-arrow;
                vertical-align: middle;

                &:before {
                    color: $color-text-primary;
                    font-size: 41px;
                    position: relative;
                }
            }
        }
    }
}
