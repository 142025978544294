﻿.middle-components {
    padding: 40px 0 0 0;

    @include breakpoint($sm) {
        padding-top: 150px;
    }

    .dots-separator {
        &:after {
            background: linear-gradient(90deg, $color-white ($separator-dot-space - $separator-dot-size), transparent 1%) center, linear-gradient($color-white ($separator-dot-space - $separator-dot-size), transparent 1%) center, $separator-color;
            background-size: $separator-dot-space $separator-dot-space;
            content: '';
            display: block;
            height: 18px;
            margin: 50px auto;
            position: relative;
            max-width: 265px;

            @include breakpoint($sm) {
                margin: 80px auto 60px;
                max-width: 335px;
            }
        }
    }
}
