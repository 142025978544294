﻿.component_faq {
    padding-top: 10px;
    color: $color-gray;

    @include breakpoint($sm) {
        padding-top: 43px;
    }

    .row {
        padding: 0 25px;

        @include breakpoint($md) {
            padding: 0 80px;
        }
    }

    h1 {
        font-size: 32px;
        line-height: 36px;
        font-weight: bold;
        font-family: $font-heading-bold;

        @include breakpoint($sm) {
            font-size: 37px;
            line-height: 42px;
        }

        @include breakpoint($md) {
            font-size: 42px;
            line-height: 48px;
        }
    }

    h2 {
        
        font-size: 28px;
        line-height: 33px;
        font-family: $font-heading-bold;

        @include breakpoint($sm) {
            font-size: 30px;
            line-height: 33px;
        }

        @include breakpoint($md) {
            font-size: 32px;
            line-height: 38px;
        }
    }

    p, ul, ol {
        
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 15px;
        font-family: $font-regular;
    }

    .contenttitle {
        @include breakpoint($xs) {
            margin-left: 0;
        }

        @include breakpoint($sm) {
            margin-left: -15px;
        }
    }

    .content {
        h1 {
            margin-bottom: 24px;
        }

        h2 {
            margin-bottom: 16px;
        }

        p, ul, ol {
            margin-bottom: 20px;
        }

        ul li {
            margin-bottom: 6px;
        }

        a {
            font-weight: 600;
        }



        a {
            &:visited,
            &:link,
            &:hover,
            &:active {
                font-size: 16px;
                color: $color-purple-primary;
                text-decoration: underline;
                font-weight: bold;
            }
        }
    }

    .contenttext {
        margin-left: 0px;

        @include breakpoint($sm) {
            margin-left: -15px;
        }

        @include breakpoint($md) {
            margin-left: -15px;
            margin-bottom: 50px;
        }


        @include breakpoint($lg) {
            margin-left: -15px;
            margin-bottom: 50px;
        }
    }
}
