﻿.content__form-zipcode {
    margin: 0 auto;
    max-width: 228px;

    .input {
        margin-bottom: 15px;
        text-align: center;
        width: 228px;
        height: 60px;
        outline: none;
        vertical-align: middle;
    }

    button {
        width: 228px;
    }
    //validations - styles
    .zip-code-error {
        font-family: $font-regular;
        font-style: italic;
        padding: 0 0 8px 4px;
        font-size: 16px;
        color: $color-error;
        margin-top: -30px;

        &:after {
            content: "-";
            color: transparent;
            display: block;
        }
    }

    .has-error {
        span {
            img {
                background-color: $color-white;
                @include border-radius(50%);
                visibility: visible;
                height: 16px;
                margin-top: -3px;
                margin-right: 4px;
            }
        }

        &#zipcodeItem {
            margin-top: -23px;
            line-height: 10px;
        }
    }

    .has-success {
        span {
            img {
                visibility: hidden;
                height: 0px;
                margin-top: 3px;
                padding-right: 0px;
            }
        }

        &#zipcodeItem {
            margin-top: 0px;
            line-height: 10px;
        }
    }
}

@mixin content__form--min-sm() {
    .content__form-zipcode {
        max-width: 100%;

        .input {
            width: 160px;
            margin-bottom: 0;
        }

        button {
            margin-left: 16px;
            max-width: none;
            width: auto;
        }
    }
}

@mixin content__form--max-sm() {
    .content__form-zipcode {
        .zip-code-error {
            padding: 0px;
            margin-top: 0px;
            line-height: 20px;
        }

        .has-error {
            &#zipcodeItem {
                margin-top: 0px;
                line-height: 10px;
            }
        }
    }
}
