@import url("//hello.myfonts.net/count/36b444");

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'Open Sans SemiBold';
    src: url('../fonts/OpenSans-SemiBold.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'Open Sans Bold';
    src: url('../fonts/OpenSans-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: bold;
}


@font-face {
    font-family: 'Open Sans Condensed';
    src: url('../fonts/OpenSansCondensed-Light.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
}


@font-face {
    font-family: 'Open Sans Condensed Bold';
    src: url('../fonts/OpenSansCondensed-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: bold;
}

@font-face {
    font-family: 'Open Sans Light';
    src: url('../fonts/OpenSans-Light.ttf') format('truetype');
    font-style: normal;
    font-weight: bold;
}
