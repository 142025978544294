﻿/* PREFIX 
    How to: @include prefix(transform, rotate(45deg), webkit ms);
*/
@mixin prefix($property, $value, $prefixes: ()) {
    @each $prefix in $prefixes {
        #{'-' + $prefix + '-' + $property}: $value;
    }
    // Output standard non-prefixed declaration
    #{$property}: $value;
}

/* BORDER RADIUS
    How to: @include border-radius(5px);
*/
@mixin border-radius($radius) {
    @include prefix(border-radius, $radius, webkit ms);
}

//Block vertical centering
@mixin center-block-vertically() {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}


/* FONT SMOOTHING
    How to: @include font-smoothing();
    Parameter: none, grayscale, auto, subpixel
    Default: antialiased
*/
@mixin font-smoothing($smooth: antialiased) {
    -webkit-font-smoothing: $smooth;
    -moz-osx-font-smoothing: grayscale;
}

/* OPACITY
    How to: @include opacity(.5);
*/
@mixin opacity($opacity) {
    opacity: $opacity;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=#{$opacity * 100})";
}

/* TRANSFORM
    How to: @include transform(scale(1.2));
*/
@mixin transform($value) {
    @include prefix(transform, $value, webkit ms);
}

/* CENTER VERTICALLY
    How to: @include center(both);
    Allowed: vertical, horizontal, both
    Defaul: vertical
*/
@mixin center($position: 'vertical') {
    position: absolute;

    @if $position == 'vertical' {
        top: 50%;
        @include prefix(transform,translateY(-50%), webkit ms);
    }
    @else if $position == 'horizontal' {
        left: 50%;
        @include prefix(transform,translateX(-50%), webkit ms);
    }
    @else if $position == 'both' {
        left: 50%;
        top: 50%;
        @include prefix(transform,translate(-50%, -50%), webkit ms);
    }
}

/* BREAKPOINTS
*/
@mixin breakpoint($width) {
    @media (min-width: $width) {
        @content;
    }
}

@mixin breakpoint-interval($min-width, $max-width) {
    @media screen and (min-width: $min-width) and (max-width: $max-width) {
        @content;
    }
}

@mixin breakpoint-max($width) {
    @media (max-width: $width) {
        @content;
    }
}

/*
    PLACEHOLDER
*/
@mixin placeholder-label($focus-color: $color-white) {
    & + label {
        color: $color-text-placeholder;
        font-style: italic;
        font-size: 24px;
        font-weight: 400;
        left: 15px;
        pointer-events: none;
        position: absolute;
        top: 11px;
        transition: all 200ms ease-out;
    }

    &:focus + label,
    &:valid + label,
    &.not-empty + label {
        color: $focus-color;
        font-size: 16px;
        left: 0;
        top: -32px;
    }
}



//Placeholder styles
@mixin optional-at-root($sel) {
    @at-root #{if(not &, $sel, selector-append(&, $sel))} {
        @content;
    }
}

@mixin placeholder {
    @include optional-at-root('::-webkit-input-placeholder') {
        @content;
    }

    @include optional-at-root(':-moz-placeholder') {
        @content;
    }

    @include optional-at-root('::-moz-placeholder') {
        @content;
    }

    @include optional-at-root(':-ms-input-placeholder') {
        @content;
    }
}

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background-clip: padding-box;
}

@mixin display-flex() {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}