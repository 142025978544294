﻿@use '../generic/forms';

.request-form {
    @include forms.honeypot-field;

    .container {
        padding: 26px 43px 98px 42px;
    }

    h1 {
        color: $color-text-primary;
        font-family: $font-heading-bold;
        font-size: 32px;
        line-height: 42px;
        text-align: center;
        margin-bottom: 40px;
    }


    .form-group {
        @include clearfix();
        margin-bottom: 30px;
    }

    .form-label {
        font-size: 18px;
        line-height: 1em;

        label {
            padding-right: 16px;
            margin-bottom: 10px;
            color: $color-text-primary;
            font-family: "Open Sans";
            font-weight: 600;
            line-height: 24px;
        }
    }

    .input-validation-error {
        font-style: italic;
        border: 2px solid $color-error;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        background: $color-pink;
        display: block;
        margin-bottom: 6px;
    }

    .field-validation-error {
        color: red;
        font-style: italic;
        margin-left: 20px;
        margin-bottom: 5px;
        display: block;

        &:before {
            content: url('/assets/images/error.svg');
            //background: url('/assets/images/error.svg'); /*url of image*/
            transform: scale(.5);
            height: 16px; /*height of image*/
            width: 33px; /*width of image*/
            position: absolute;
            margin-left: -30px;
        }
    }

    input {
        height: 60px;
        width: 444px;
        font-size: 18px;
    }

    .input-xs {
        width: 100%;

        @include breakpoint(321px) {
            width: 160px;
        }
    }

    .input-sm {
        width: 100%;

        @include breakpoint(321px) {
            width: 255px;
        }
    }

    .input-md {
        width: 100%;

        @include breakpoint(321px) {
            width: 290px;
        }
    }


    .input-lg {
        width: 100%;

        @include breakpoint(321px) {
            width: 290px;
        }
    }

    .form-disclaimer {
        color: #87888C;
        font-family: "Open Sans";
        font-size: 14px;
        line-height: 24px;

        a {
            color: inherit;
            text-decoration: underline;

            &[href^="tel:"] {
                text-decoration: none;
            }
        }
    }

    .required {
        font-size: 18px;
    }
}
@mixin request-form--min-sm() {
    .request-form {
        .container {
            padding: 75px 0px 70px 0px;
            max-width: 920px;
        }

        h1 {
            font-size: 37px;
            line-height: 42px;
            text-align: center;
            margin-bottom: 40px;
        }

        .form-group {
            @include clearfix();
            margin-bottom: 40px;
        }

        .form-label {
            margin-top: 20px;
            font-size: 18px;
            text-align: right;
            vertical-align: middle;

            label {
                padding-right: 0px;
            }
        }

        .input-xs {
            width: 160px;
        }

        .input-sm {
            width: 255px;
        }

        .input-md {
            width: 444px;
        }

        .input-lg {
            width: 444px;
            border-radius: 0;
        }

        .form-disclaimer {
            font-size: 16px;
            line-height: 22px;
            text-align: center;
        }
    }
}
@mixin request-form--min-md() {
    .request-form {
        h1 {
            font-size: 42px;
            line-height: 52px;
            text-align: center;
            margin-bottom: 40px;
        }

        .input-lg {
            width: 539px;
        }
    }
}
