﻿.step-progress-bar {
    z-index: 2;
    background-color: $color-purple-primary;

    .container {
        width: 100%;
    }

    @include breakpoint($sm) {
        margin-bottom: 0;
    }

    @include breakpoint($lg) {
        background-color: transparent;
        left: 50%;
        position: fixed;
        top: 40px;
        transform: translate(-50%, 0);
        z-index: $z-index-top + 10;
        width: 50%;
    }

    &__content {
        margin: 42px auto 0;
        overflow: hidden;
        padding: 0;

        @include breakpoint-max($sm - 1) {
            max-width: 300px;
        }

        @include breakpoint($sm) {
            margin-top: 32px;
            margin-bottom: 20px;
            max-width: 560px;
        }

        @include breakpoint($lg) {
            margin-top: 0;
            max-width: 640px;
        }
    }

    &__item {
        float: left;
        list-style-type: none;
        position: relative;
        text-align: center;
        text-transform: uppercase;
        width: 25%;

        p {
            color: $text-default-color-step;
            display: inline-block;
            font-family: $font-heading-bold;
            font-size: 10px;
            font-weight: 700;
            line-height: 1.1;
            max-width: 60px;

            @include breakpoint($sm) {
                max-width: 94px;
                font-size: 16px;
                line-height: 18px;
            }
        }

        .icon-location {
            display: inline-block;
        }

        &:before {
            background-color: $circle-default-color-step;
            border: 5px solid $color-white;
            border-radius: 100%;
            box-shadow: 0 3px 6px 0 rgba(0,0,0,0.12);
            content: '';
            display: block;
            height: 22px;
            margin: 7px auto 8px;
            position: relative;
            width: 22px;
            z-index: 5;

            @include breakpoint($sm) {
                height: 26px;
                margin-top: 10px;
                width: 26px;
            }
        }

        &:first-child:after {
            content: none;
        }

        &:after {
            background-color: $line-default-color-step;
            box-shadow: inset 0 1px 3px 0 rgba(255,255,255,0.5);
            content: '';
            height: 4px;
            left: -50%;
            position: absolute;
            top: 15px;
            width: 100%;
            z-index: 1;

            @include breakpoint($sm) {
                top: 23px;
            }
        }

        &:last-child {
            p {
                display: block;
                max-width: 100%;
            }
        }

        &--active {
            p {
                color: $text-step-active;
                font-size: 14px;
                line-height: 1;

                @include breakpoint($sm) {
                    font-size: 16px;
                    line-height: 1.125;
                }
            }
        }
    }

    &__item-location {
        position: relative;

        &:before {
            background-color: $circle-active-color;
            border: none;
            box-shadow: none;
            content: '';
            height: 38px;
            margin: 0 auto 5px;
            width: 38px;

            @include breakpoint($sm) {
                height: 56px;
                width: 56px;
            }
        }

        .location-img {
            background: url('/assets/images/ma-location.svg') no-repeat;
            background-size: contain;
            height: 25px;
            left: 50%;
            margin: 0 auto;
            margin-left: -9px;
            margin-top: -34px;
            position: absolute;
            top: 50%;
            width: 25px;
            z-index: 10;

            @include breakpoint($sm) {
                top: 50%;
                height: 35px;
                margin-left: -12px;
                margin-top: -43px;
                width: 35px;
            }

            @include breakpoint($md) {
                margin-left: -12px;
            }

            @include breakpoint($lg) {
                margin-top: -43px;
            }
        }

        p {
            display: inline-block;
            width:60px;
        }
    }

    &--scroll-down-effect {
        @include breakpoint($lg) {
            ul {
                li {
                    p {
                        opacity: 0;
                        transition-property: opacity;
                        transition-duration: 0.3s;
                    }

                    .location-img {
                        opacity: 0;
                    }

                    &:first-child:before {
                        background-color: $color-white;
                        border: 5px solid $circle-active-color;
                        border-radius: 100%;
                        box-shadow: 0 3px 6px 0 rgba(0,0,0,0.12);
                        content: '';
                        display: block;
                        height: 29px;
                        margin: 10px auto 8px;
                        margin-top: 10px;
                        transition-duration: 0.3s;
                        transition-property: height, background-color, box-shadow,margin-top,width;
                        width: 29px;
                    }
                }
            }
        }
    }

    &--scroll-up-effect {
        @include breakpoint($lg) {
            ul {
                li {
                    p, .location-img {
                        opacity: 1;
                        transition-property: opacity;
                        transition-duration: 0.3s;
                    }

                    &:first-child:before {
                        transition-duration: 0.3s;
                        transition-property: height, background-color, box-shadow,margin-top,width;
                    }
                }
            }
        }
    }
}
