﻿.enroll-online {
    text-align: center;

    .enroll-online--title {
        margin-bottom: 25px;

        h1 {
            max-width: 280px;
            margin: 0 auto;
            font-family: $font-heading-bold;
            font-size: 32px;
            color: $color-text-primary;
            line-height: 42px;

            sup {
                font-size: 56%;
                top: -0.5em;
            }

            @include breakpoint($sm) {
                font-size: 37px;
                line-height: 42px;
                max-width: 600px;
            }

            @include breakpoint($md) {
                font-size: 42px;
                line-height: 50px;
            }
        }
    }

    .enroll-online--content {
        margin-bottom: 35px;

        p {
            max-width: 290px;
            margin: 0 auto 10px auto;
            font-size: 16px;
            color: $color-text-secondary;
            line-height: 26px;

            @include breakpoint($sm) {
                max-width: 650px;
            }
        }
    }

    .enroll-online--icon {
        margin-bottom: 10px;

        @include breakpoint($sm) {
            margin-bottom: 20px;
        }

        img {
            height: 48px;

            @include breakpoint($sm) {
                height: 76px;
            }
        }
    }
}
