﻿.component_text {
    margin-left: 28px;
    margin-right: 27px;
    padding-top: 20px;
    margin-bottom: 80px;
    color: $color-gray;

    @include breakpoint($sm) {
        margin-left: 30px;
        margin-right: 30px;
    }


    h1 {
        font-size: 28px;
        line-height: 42px;
        font-weight: bold;
        font-family: $font-heading-bold;

        @include breakpoint($sm) {
            font-size: 37px;
            line-height: 42px;
        }

        @include breakpoint($md) {
            font-size: 42px;
            line-height: 52px;
            padding-top: 49px;
            margin-bottom: 30px;            
        }
    }

    h2 {        
        font-size: 24px;
        line-height: 38px;
        font-family: $font-heading-bold;
        padding-top: -20px;
        padding-bottom: 2px;

        @include breakpoint($sm) {
            font-size: 30px;
            line-height: 33px;
        }

        @include breakpoint($md) {
            font-size: 32px;
            line-height: 42px;            
        }
    }

    h3 {        
        font-family: $font-heading-bold;
        font-size: 22px;
        line-height: 50px;

        @include breakpoint($sm) {
            font-size: 28px;
            line-height: 34px;
        }

        @include breakpoint($md) {
            font-size: 28px;
            line-height: 38px;
        }
    }

    a {
        color: $color-purple-primary;
        font-family: $font-regular-bold;
        font-size: 16px;
        line-height: 26px;
    }

    p, ul, ol {        
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 32px;
        font-family: $font-regular;
    }


    .reference {
        font-size: 12px;
        line-height: 17px;        
        margin-top: 25px;
        margin-bottom: 30px;

        @include breakpoint($sm) {
            margin-top: 25px;
            margin-bottom: 48px;
        }
    }

    p.lastupdate {
        font-size: 12px;
        line-height: 17px;
    }


    div.divh2 {
        margin-left: 16px;

        @include breakpoint($sm) {
            margin-left: 32px;
        }
    }
}
