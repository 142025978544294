﻿.mobile-cta {
    width: 70px;
    height: 70px;
    background-color: $color-light-blue;
    @include border-radius(50%);
    position: fixed;
    bottom: 43px;
    right: 17px;
    box-shadow: 5px 9px 21px -1px rgba(0, 0, 0, 0.5);
    padding: 0 0 0 0px;
    z-index: 99;

    a {
        display: block;
        line-height: 57px;
        text-align: center;
        text-decoration: none;

        img {
            width: 70px;
            height: 70px;
        }
    }
}