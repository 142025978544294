﻿/* General Reset */
html {
    height: 100%;
}

body {
    height: 100%;
    padding-top: $navbar-height-mobile;
    font-weight: 400;
    line-height: 1.6;
    font: 400 16px/1.4 $font-regular;
}

@mixin base--min-sm() {
    body {
        padding-top: $navbar-height;
    }
}

@mixin base--min-lg() {
    a[href^="tel:"] {     
        text-decoration: none;     
    }

    .is--cellphone {
        display: inline-block;
        position: relative;
        z-index: 0;

        &:before,
        &:after {
            //content: " ";
            position: absolute;
            left: 0;
            right: 0;
            height: 100%;
            z-index: 99;
            background-color: transparent;
            opacity: 0;
        }
    }
}