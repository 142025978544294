﻿.easy-get-drug-coverage {
    margin-bottom: 50px;

    @include breakpoint($sm) {
        margin-bottom: 85px;
    }

    .easy-get-drug-coverage--title {
        margin-bottom: 25px;
        text-align: center;

        @include breakpoint($sm) {
            margin-bottom: 70px;
        }

        h1 {
            max-width: 290px;
            margin: 0 auto 30px auto;
            font-family: $font-heading-bold;
            font-size: 32px;
            color: $color-text-primary;
            line-height: 42px;

            @include breakpoint($sm) {
                font-size: 37px;
                line-height: 42px;
                max-width: 810px;
            }

            @include breakpoint($md) {
                font-size: 42px;
                line-height: 50px;
            }
        }
    }

    .easy-get-drug-coverage--content {
        text-align: center;

        @include breakpoint($md) {
            margin: 45px auto auto 80px;
            text-align: left;
        }

        .row {
            margin-bottom: 45px;

            @include breakpoint($md) {
                margin-bottom: 0;
            }

            &:nth-of-type(1) {
                .step-info {
                    max-width: 260px;
                }
            }

            &:last-child {
                margin-bottom: 30px;

                @include breakpoint($md) {
                    margin-bottom: 5px;
                }
            }

            .item-icon {
                background-repeat: no-repeat;
                background-size: cover;
                display: block;
                height: 70px;
                width: 70px;
                position: relative;
                margin: 0 auto 25px auto;

                @include breakpoint($md) {
                    height: 40px;
                    width: 40px;
                    float: left;
                    top: 10px;
                }

                &.item-icon-magnifyingglass {
                    background-image: url("/assets/images/magnifyingglass.svg");
                }

                &.item-icon-clipboard {
                    background-image: url("/assets/images/clipboard.svg");
                    height: 80px;
                    width: 62px;

                    @include breakpoint($md) {
                        height: 46px;
                        width: 36px;
                    }
                }

                &.item-icon-checkmark {
                    background-image: url("/assets/images/checkmark.svg");
                }
            }

            .step-info {
                position: relative;
                font-size: 16px;
                line-height: 26px;
                max-width: 220px;
                margin: 0 auto;

                @include breakpoint($md) {
                    float: left;
                    margin-left: 20px;
                    max-width: 274px;
                    line-height: 25px;
                }

                h4 {
                    font-family: $font-regular-bold;
                    color: $color-text-primary;
                }
            }
        }
    }

    .easy-get-drug-coverage--border {
        border: none;
        box-shadow: none;
        $background-end-color: #eae6f3;

        @include breakpoint($md) {
            border: 1px solid #979797;
            background: linear-gradient(155deg, #FFFFFF 60%, $background-end-color 100%);
            box-shadow: 0 0 4px 0 rgba(0,0,0,0.18);
            height: 442px;
        }
    }

    .easy-get-drug-coverage--image {
        position: absolute;
        display: block;
        width: 570px;
        height: 419px;
        background-repeat: no-repeat;
        background-size: cover;
        pointer-events: none;
        top: 21px;
        right: 0;

        @include breakpoint($xg) {
            height: 453px;
            top: -13px;
            right: 40px;
            width: 600px;
        }
    }
}
