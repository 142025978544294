﻿$mobile-large : 480px;

.hero-splash {
    background-color: $color-purple-primary;
    height: auto;
    width: 100%;

    &__body {

        .content {
            float: none;
            margin: 20px auto 0px auto;
            text-align: center;
            color: $color-white;
            padding: 0px 43px;

            @include breakpoint($lg) {
                margin: 109px auto 0px auto;
            }


            p {
                font-size: 32px;
                line-height: 36px;
                font-family: $font-heading-bold;
                font-weight: bold;
                letter-spacing: 0;

                @include breakpoint($sm) {
                    font-size: 37px;
                    line-height: 42px;
                }

                @include breakpoint($lg) {
                    font-size: 42px;
                    line-height: 48px;
                }
            }

            ul {
                list-style-position: inside;
                margin-top: 25px;
                font-family: $font-regular;
                font-size: 16px;
                line-height: 26px;
                margin-left: -25px;


                @include breakpoint($lg) {
                    font-size: 20px;
                    line-height: 30px;
                }
            }

            form {
                margin-top: 35px;
                display: inline-block;
            }
        }
    }

    &__footer {
        width: 100%;
        min-height: 150px;

        @include breakpoint($sm) {
            min-height: 100px;
        }

        .icons-container {
            align-items: center;
            display: grid;
            justify-content: center;
            grid-template-columns: repeat(2, auto);
            grid-gap: 25px;
            margin: 48px auto 40px;
            max-width: 320px;

            @include breakpoint($sm) {
                grid-template-columns: repeat(5, auto);
                max-width: none;
                grid-column-gap: 15px;
            }

            @include breakpoint($xg) {
                grid-column-gap: 25px;
            }

            .logo {
                width: fit-content;
                margin: auto;

                &:nth-last-child(1) {
                    &:not(odd) {
                        grid-column: span 2;

                        @include breakpoint($sm) {
                            grid-column: revert;
                        }
                    }
                }

                &--wellcare {
                    .icon {
                        font-size: 18px;

                        @include breakpoint($lg) {
                            font-size: 32px;
                        }
                    }
                }

                &--moo {
                    .icon {
                        font-size: 28px;

                        @include breakpoint($sm) {
                            font-size: 32px;
                        }

                        @include breakpoint($lg) {
                            font-size: 45px;
                        }
                    }
                }

                &--aetna {
                    .icon {
                        font-size: 19px;

                        @include breakpoint($sm) {
                            font-size: 24px;
                        }

                        @include breakpoint($lg) {
                            font-size: 32px;
                        }
                    }
                }

                &--humana {
                    .icon {
                        font-size: 18px;

                        @include breakpoint($mobile-large) {
                            font-size: 24px;
                        }

                        @include breakpoint($lg) {
                            font-size: 32px;
                        }
                    }
                }

                &--anthembcbs {
                    .icon {
                        font-size: 20px;

                        @include breakpoint($mobile-large) {
                            font-size: 25px;
                        }

                        @include breakpoint($lg) {
                            font-size: 34px;
                        }
                    }
                }
            }
        }
    }
}
