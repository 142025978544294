﻿.steps {
    background-color: $color-white;
    text-align: center;

    &__wrapper {
        padding: 40px 0 0px;

        @include breakpoint($sm) {
            padding: 52px 0 0px;
        }

        @include breakpoint($md) {
            padding: 60px 0 0px;
        }
    }

    &__title {
        margin-bottom: 48px;

        @include breakpoint($lg) {
            margin-bottom: 60px;
        }

        h2 {
            color: $color-text-primary;
            font-family: $font-heading-bold;
            font-size: 32px;
            font-weight: bold;
            line-height: 36px;
            margin: 0 auto;
            max-width: 303px;
            text-align: center;

            @include breakpoint($sm) {
                font-size: 37px;
                line-height: 48px;
                max-width: 510px;
            }

            @include breakpoint($lg) {
                font-size: 42px;
                max-width: 100%;
            }
        }
    }

    &__content {
        margin: 0 auto;
        max-width: 248px;

        @include breakpoint($sm) {
            @include display-flex();
            align-items: baseline;
            flex-direction: row;
            max-width: 690px;
        }

        @include breakpoint($lg) {
            max-width: 930px;
        }

        .step {
            display: block;
            width: 100%;
            margin-bottom: 20px;
            padding-left: 12px;
            padding-right: 12px;

            &:last-child {
                margin-bottom: 0;

                @include breakpoint($sm) {
                    margin-right: 0;
                }
            }

            @include breakpoint($sm) {
                display: inline-block;
                max-width: 156px;
                padding: 0;
                vertical-align: top;
                margin-bottom: 0;
                margin-right: 24px;
            }

            @include breakpoint($lg) {
                max-width: 190px;
                margin-right: 57px;
            }

            img {
                max-height: 85px;
                max-width: 119px;
                margin: 0 auto 10px;

                @include breakpoint($sm) {
                    max-width: 110px;
                    margin-bottom: 12px;
                }

                @include breakpoint($md) {
                    max-height: 123px;
                    max-width: 174px;
                    margin-bottom: 14px;
                }
            }

            h2 {
                color: $color-text-secondary;
                font-family: $font-regular-bold;
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 26px;
                margin-bottom: 5px;

                @include breakpoint($sm) {
                    margin-bottom: 3px;
                }

                @include breakpoint($md) {
                    margin-bottom: 10px;
                }
            }

            p {
                color: $color-text-secondary;
                font-size: 16px;
                font-weight: 300;
                letter-spacing: 0;
                line-height: 26px;
            }
        }
    }
}
