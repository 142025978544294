﻿.need-coverage {
    margin-top: 50px;
    text-align: center;

    @include breakpoint($sm) {
        margin-top: 70px;
    }

    .need-coverage--logo {
        padding-top: 30px;
        text-align: center;

        img {
            @include breakpoint-max($sm) {
                width: 240px;
                height: 240px;
            }
        }

        @include breakpoint($md) {
            text-align: right;
        }
    }

    .need-coverage--logo2 {
        img {
            width: 200px;

            @include breakpoint($sm) {
                width: auto;
            }
        }
    }

    .need-coverage--title {
        margin-bottom: 20px;

        h1 {
            max-width: 290px;
            margin: 0 auto;
            font-family: $font-heading-bold;
            font-size: 32px;
            color: $color-text-primary;
            line-height: 42px;

            @include breakpoint($sm) {
                font-size: 37px;
                line-height: 42px;
                max-width: 810px;
            }

            @include breakpoint($md) {
                font-size: 42px;
                line-height: 50px;
            }
        }
    }

    .need-coverage--content {
        max-width: 290px;
        margin: 0 auto;
        color: $color-text-secondary;
        line-height: 26px;

        p {
            margin-bottom: 30px;
        }

        p:last-of-type {
            margin-bottom: 0;
        }

        @include breakpoint($md) {
            max-width: 373px;
            text-align: left;
            margin: 80px auto 37px 50px;
        }
    }

    .need-coverage--title2 {
        margin-bottom: 25px;

        h1 {
            font-family: $font-heading-bold;
            font-size: 32px;
            color: $color-purple-secondary;

            @include breakpoint($sm) {
                font-size: 60px;
            }
        }

        p {
            max-width: 320px;
            margin: 0 auto;
            font-family: $font-regular-bold;
            font-weight: bold;
            font-size: 20px;
            color: $color-text-primary;

            sup {
                font-size: 65%;
                top: -0.6em;
            }

            @include breakpoint($sm) {
                max-width: 810px;
            }
        }
    }
}

.need-coverage--footer {
    background-color: $color-purple-secondary;
    box-shadow: 2px 4px 6px 0 rgba($color-black,0.1);
    color: $color-white;
    margin-top: 40px;
    padding: 12px 0 10px 0;
    position: relative;
    max-width: 83%;
    bottom: -50px;

    @include breakpoint($sm) {
        background-color: $color-purple-secondary;
        left: 0;
        margin-top: 30px;
        padding: 0;
        position: relative;
    }

    @include breakpoint($md) {
        bottom: -30px;
    }

    @include breakpoint($xg) {
        width: 83.9%;
        max-width: 83.9%;
    }

    .__content {

        @include breakpoint($xs) {
            margin-left: 66px;
        }


        @include breakpoint($sm) {
            background-color: $color-purple-secondary;
            padding: 24px 0 14px 20px;
            position: relative;
        }

        @include breakpoint($md) {
            padding: 24px 5.5% 14px 20px;
        }

        @include breakpoint($lg) {
            padding: 24px 10% 14px 20px;
        }

        @include breakpoint($xg) {
            padding: 24px 105px 14px 20px;
            height: 68px;
        }

        &:after {
            content: "";
            background-color: $color-purple-secondary;
            box-shadow: 2px 4px 6px -2px rgba($color-black,0.1);
            display: block;
            height: 100%;
            right: -23px;
            position: absolute;
            width: 50px;
            top: 0;
            transform: skew(-20deg);
            -webkit-transform: skew(-20deg);
            z-index: 1;

            @include breakpoint-max($md) {
                right: -28px;
                width: 60px;
            }
        }
    }

    .content__text-wrap {
        font-size: 16px;
        position: relative;
        text-align: center;
        font-weight: 500;
        line-height: 20px;
        font-family: $font-regular;

        p {
            sup {
                font-size: 62%;
                top: -0.7em;
            }
        }

        @include breakpoint($xs) {
            z-index: 10;
            text-align: center;
            max-width: none;
        }


        @include breakpoint($sm) {
            z-index: 2;
            text-align: center;
            max-width: none;
        }

        @include breakpoint($md) {
            text-align: right;
        }

        @include breakpoint($xg) {
            margin-left: 5%;
            line-height: 14px;
            position: absolute;
            width: 100%;
            text-align: center;
        }

        @include breakpoint(1440px) {
            margin-left: 6%;
        }

        @include breakpoint(1680px) {
            margin-left: 7%;
        }
    }
}
/// style mods for spanish language version
.need-coverage--footer {
    @include breakpoint($sm) {
        .__content:after {
            right: -30px;
            width: 70px;
        }
    }
}