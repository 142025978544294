﻿.zipcode-at-bottom--container {
    background-color: $color-purple-primary;
    padding: 112px 0 25px 0;

    @include breakpoint($sm) {
        padding-bottom: 55px;
    }

    .container {
        text-align: center;

        .zipcode-at-bottom--title {
            height: 100px;
            color: #FFFFFF;
            font-family: "Open Sans Condensed Bold";
            font-weight: bold;
            line-height: 50px;
            text-align: center;
            margin-bottom: 45px;

            h1 {
                font-size: 32px;
                max-width: 300px;
                margin: 0 auto;
            }

            @include breakpoint($sm) {
                margin-bottom: 44px;

                h1 {
                    font-size: 37px;
                    line-height: 42px;
                    max-width: 510px;
                }
            }

            @include breakpoint($md) {
                h1 {
                    font-size: 42px;
                    line-height: 50px;
                }
            }
        }

        .zipcode-at-bottom--form {
            margin-bottom: 47px;

            @include breakpoint($sm) {
                #zipcodeItem {
                    max-width: 405px;
                    margin: 0 auto;
                }
            }
        }

        .zipcode-at-bottom--phone-number {
            margin-bottom: 44px;

            .tty {
                color: $color-light-purple;
                font-family: $font-regular-light;
                font-size: 40px;
                font-weight: 300;
                line-height: 55px;
            }

            .phone {
                color: $color-light-blue;
                font-family: $font-regular-bold;
                font-size: 40px;
                font-weight: bold;
                line-height: 55px;
                margin-bottom: -4px;
                margin-top: -11px;

                a {
                    color: $color-white;

                    &:hover,
                    &:visited,
                    &:active,
                    &:focus {
                        text-decoration: none;
                    }
                }
            }
        }

        .form--badges {
            height: 60px;
            text-align: center;
        }

        .zipcode-at-bottom-disclaimer {
            color: white;
            margin-top: 30px;
            font-size: 17px;

            @include breakpoint($sm) {
                font-size: 18.5px;
            }

            p {
                margin-bottom: 0;
            }
        }
    }
}
