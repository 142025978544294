﻿.notfound-component {
    margin-left: 12px;
    margin-right: 12px;


    .title {
        color: $color-gray;
        font-family: $font-heading-bold;
        font-size: 42px;
        font-weight: bold;
        line-height: 52px;
        text-align: center;
        margin-top: 30px;
        margin-bottom: 23px;
    }

    h1 {
        color: $color-gray;
        font-family: $font-heading-bold;
        font-size: 20px;
        font-weight: bold;
        line-height: 38px;
        text-align: center;
        margin-bottom: 25px;
    }

    p {
        color: $color-text-secondary;
        font-family: $font-regular;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        margin-bottom: 20px;
    }

    a {
        color: $color-purple-primary;
        font-family: $font-regular-bold;
        font-size: 18px;
        font-weight: bold;
        line-height: 42px;
        text-align: center;
        text-decoration: underline;

        span {
            display: inline-block;
            font-size: 16px;
            @extend .icon;
            @extend .icon-right-arrow-purple;
            vertical-align: middle;
            position: relative;
            margin-left: 6px;            
        }
    }

    img {
        margin-top: 30px;
        margin-bottom: -20px;
    }
}



@mixin notfound-component--min-xs() {
    .notfound-component {
        margin-left: 30px;
        margin-right: 30px;              
    }
}

@mixin notfound-component--min-xsm() {
    .notfound-component {
        margin-left: 32px;
        margin-right: 32px;

        h1 {
            font-size: 32px;
        }

        img {
            margin-top: 10px;
        }
    }
}

@mixin notfound-component--min-sm() {
    .notfound-component {
        .title {
            font-size: 81px;
            font-weight: bold;
            line-height: 36px;
            margin-top: 118px;
            margin-bottom: 60px;

            &.error {
                line-height: 80px;
            }
        }
    }
}