﻿@font-face {
  font-family: 'myrxplans';
  src: url('../fonts/myrxplans.eot?h5687o');
  src:
    url('../fonts/myrxplans.eot?h5687o#iefix') format('embedded-opentype'),
    url('../fonts/myrxplans.woff2?h5687o') format('woff2'),
    url('../fonts/myrxplans.ttf?h5687o') format('truetype'),
    url('../fonts/myrxplans.woff?h5687o') format('woff'),
    url('../fonts/myrxplans.svg?h5687o#myrxplans') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon,
[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'myrxplans' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @include font-smoothing();
}

/*.icon-mutual-reversed-white:before {
    content: "\e90f";
    color: #fff;
}*/

.icon-wellcare-reverse:before {
  content: "\e900";
  color: #fff;
}

.icon-CallButton:before {
  content: "\e925";
  color: #2aabf7;
}

.icon-myrxplans-horizontal-black:before {
  content: "\e904";
}

.icon-myrxplans-horizontal-reverse:before {
  content: "\e913";
  color: #fff;
}

.icon-myrxplans-stacked-black:before {
  content: "\e914";
}

.icon-myrxplans-stacked-reverse:before {
  content: "\e920";
  color: #fff;
}

.icon-aetna-reverse:before {
  content: "\e902";
  color: #fff;
}

.icon-anthembcbs-reverse:before {
  content: "\e922";
  color: #fffffe;
}

.icon-humana-reverse:before {
  content: "\e923";
  color: #fff;
}

.icon-moo-reverse:before {
  content: "\e901";
  color: #fff;
}

.icon-better-business-bureaua:before {
  content: "\e924";
  color: #fff;
}

.icon-norton-secure-seal-white:before {
  content: "\e9fc";
  color: #fff;
}

.icon-right-arrow:before {
  content: "\ea30";
  color: #fff;
}

.icon-right-arrow-purple:before {
  content: "\ea30";
  color: $color-purple-primary;
}

.icon-secure-website-white:before {
  content: "\ea31";
  color: #fff;
}

.icon-down-arrow:before {
  content: "\ea33";
  color: #fff;
}

.icon-left-arrow:before {
  content: "\ea34";
  color: #fff;
}