﻿.sidebar {
    font-size: 24px;
    background-color: $color-purple-primary;
    color: $color-white;
    text-align: center;
    width: 100%;
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 30px;
    padding: 37px 29px;

    @include breakpoint($sm) {
        margin: 20px -15px 0 auto;
        width: 100%;
    }

    @include breakpoint($md) {
        max-width: 350px;
    }

    h1 {
        font-family: $font-heading-bold;
        color: inherit;
        font-size: 28px;
        line-height: 38px;
        margin-top: 0;

        @include breakpoint($md) {
            font-size: 32px;
            line-height: 41px;
        }
    }


    p {
        font-size: 18px;
        color: inherit;
        font-family: $font-regular;
        margin-bottom: 0px;

        @include breakpoint($md) {
            margin-left: -15px;
            margin-right: -15px;
        }

        &:first-of-type {
            margin-bottom: 0;
        }

        &.tfn {
            font-size: 23px;
            font-family: $font-regular-bold;
            margin-bottom: 15px;
        }

        &.tty {
            font-size: 32px;
            font-family: $font-regular-light;
            padding-top: 0;
            color: $progress-bar-color;
        }
    }

    a {
        display: block;
        font-size: 1.25em;
        font-weight: 600;
        color: inherit;
        margin-top: 0.333em;
    }
    /*zip code seccion*/
    .content__form-zipcode {
        margin: 0 auto;
        max-width: 228px;

        .input {
            margin-top: 18px;
            margin-bottom: 15px;
            width: 228px;
        }

        button {
            margin-left: 0px;
            margin-bottom: 28px;
            width: 228px;
        }
        //validations - styles
        .zip-code-error {
            font-family: $font-regular;
            font-style: italic;
            padding: 0 0 8px 4px;
            font-size: 16px;
            color: $color-error;
            margin-top: -30px;
            line-height: 20px;

            &:after {
                content: "-";
                color: transparent;
                display: block;
            }
        }

        .has-error {
            span {
                img {
                    background-color: $color-white;
                    @include border-radius(50%);
                    visibility: visible;
                    height: 16px;
                    margin-top: -3px;
                    margin-right: 4px;
                }
            }

            .input {
                margin-top: 0px;
            }

            &#zipcodeItem {
                margin-top: 23px;
                line-height: 10px;
            }
        }

        .has-success {
            span {
                img {
                    visibility: hidden;
                    height: 0px;
                    margin-top: 3px;
                    padding-right: 0px;
                }
            }

            &#zipcodeItem {
                margin-top: 0px;
                line-height: 10px;
            }
        }
    }
}


@mixin content__form--min-sm() {
    .sidebar {
        .content__form-zipcode {
            max-width: 100%;

            .input {
                margin-bottom: 12px;
            }

            button {
                max-width: none;
                margin-bottom: 28px;
                margin-left: 0 !important;
            }
        }
    }
}

@mixin content__form--max-sm() {
    .sidebar {
        .content__form-zipcode {
            .zip-code-error {
                padding: 0px;
                margin-top: 0px;
                line-height: 20px;
            }

            .has-error {
                &#zipcodeItem {
                    margin-top: 0px;
                    line-height: 10px;
                }
            }
        }
    }
}
