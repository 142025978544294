﻿.site-header {
    background-color: $color-white;
    color: $color-text-secondary;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: $navbar-height-mobile;
    padding: 0 15px;
    z-index: $z-index-top;
    margin-top: 0;
    box-shadow: 1px 3px 5px 0 rgba(0,0,0,0.16);
    @include clearfix();

    @include breakpoint($sm) {
        padding-left: 36px;
        padding-right: 20px;
    }

    &.sticky {
        position: fixed;
        margin-top: 0;
    }

    .header-logo {
        float: left;
        margin: 18px 0 0 0;

        img {
            position: relative;
            display: inline-block;
            vertical-align: middle;
            width: 139px;
            height: 30px;
        }
    }

    .call {
        float: right;
        font-family: 'Open Sans Condensed Bold';
        font-size: 22px;
        font-weight: bold;
        line-height: 24px;
        text-align: right;

        .call-container {
            display: table;
            height: 100%;

            .call-cell {
                display: table-cell;
                vertical-align: middle;

                .call-action-text {
                    font-size: 15px;

                    p {
                        margin-bottom: 0;
                    }
                }

                .call-phone-number {

                    a {
                        color: $color-light-blue;
                    }

                    .call-separator {
                        font-size: 15px;
                        vertical-align: top;
                        margin: 0 5px;
                    }
                }
            }
        }
    }

    .call-mobile {
        a {
            color: $color-light-blue;
        } 
        margin-top: 19.4px; 
    }

    .header-cta {
        float: right;
        height: 100%;

        .btn {
            height: 44px;
            margin: 11px 0 0 0;
            padding: 13px 10px;

            span {
                margin-left: 6px;
            }
        }
    }
}

@mixin site-header--min-sm() {
    .site-header {
        height: $navbar-height;
        margin-top: 0;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);

        .header-logo {
            margin: 10px 0 0;

            img {
                width: 222px;
                height: 47px;
            }
        }
    }
}