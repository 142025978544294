﻿.site-footer {
    background-color: $color-l-gray;
    color: $color-white;
    padding: 40px 0 60px;

    .footer-content {

        .footnotes, .legal {
            word-wrap: break-word;
            overflow-wrap: break-word;
            font-size: 16px;
            font-weight: 300;
            color: $color-text-legal-secondary;
        }

        .footnotes {
            p {
                padding-left: 18px;
                text-indent: -18px;
            }
        }

        a {
            color: inherit;
            text-decoration: underline;
        }
	}
	
	.security-logos {
		margin: 0 0 20px;
		text-align: left;

		@include breakpoint($sm) {
			max-width: none;
			margin: 0 0 25px 35px;
			float: right;
			text-align: right;
		}

		.bbb {
		  margin-bottom: 14px;
		  display: block;

			@include breakpoint($sm) {
				margin-right: 22px;
				float: left;
				margin-top: 0;
				margin-bottom: 9px;
			}
		}
	}
}

.form--badges {
    margin: 20px 0 0 0;
    line-height: 36px;
    height: 57px;

    @include breakpoint($sm) {
        margin: 0;
        text-align: right;
    }

    .badge, .icon, i, i:before, i::before {
        line-height: 36px;
        height: 57px;
    }
}

.site-footer-links {
    width: 100%;
    background-color: $color-gray;
    color: $color-white;
    padding: 18px 0 0px;

    a {
        color: inherit;
    }

    .footer-nav {
        color: $color-white;
        font-size: 0;
        margin: 0 20px 40px 20px;

        ul > li {
            font-size: 16px;
            display: block;
            text-align: left;
            margin: 20px 0;

            a {
                text-decoration: underline;
            }
        }
    }
}


@mixin site-footer--max-sm() {
    .site-footer {
        .footer-content {
            padding: 0 35px;
        }
    }   
}

@mixin site-footer--min-sm() {

        .form--badges {
            margin: 0;
        }

        .site-footer-links {
            padding: 40px 0 0px;

            .footer-nav {
                margin-left: 28px;

                ul > li {
                    border-top: none;
                    display: inline-block;
                    margin: 0;
                    padding: 7px 0;

                    &:after {
                        content: "|";
                        display: inline-block;
                        text-align: center;
                        margin: 0 21px;
                    }

                    &:last-of-type:after {
                        content: none;
                    }
                }
            }
        }
    }

@mixin site-footer--min-md() {
    .site-footer-links {
        .footer-nav {
            margin-left: 5px;
        }
    }
}