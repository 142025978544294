﻿/* Global classes like .button, .title, etc. */

.btn {
    color: $color-white;
    border: none;
    @include border-radius(0);
    transition: background-color 0.2s ease;
    font-size: 16px;
    line-height: 1.1;
    height: 60px;
    font-weight: 400;
    box-shadow: 1px 3px 5px 0 rgba(0,0,0,0.16);
    padding: 20px 40px;

    span {
        margin-left: 10px;
        top: -1px;
    }

    &.btn-primary {
        background: $color-light-blue;
        text-decoration: none;

        &:focus,
        &:hover,
        &:active,
        &:active:focus {
            background-color: darken($color-light-blue, 10%);
            box-shadow: none;
            outline: none;
        }

        span {
            display: inline-block;
            @extend .icon;
            @extend .icon-right-arrow;
            vertical-align: middle;
            position: relative;
        }
    }
}

.form-control {
    border: 1px solid $color-form-control-border;
    @include prefix(box-shadow, (inset 1px 1px 2px 0 rgba(0,0,0,0.5)), webkit ms);
    height: $input-height-small;
    font-size: 20px;
    color: $color-form-control-text;    
}

@mixin global--min-sm() {
    .form-control {
        font-size: 24px;
        height: $input-height-large;
    }

    .btn {
        font-size: 16px;
    }
}

.form--badges {
    list-style: none;
    text-align: center;
    margin-top: 28px;

    @include breakpoint($sm) {
        margin-top: 15px;
    }

    .badge {
        display: inline-block;
        color: $color-white;
        margin-right: 17px;
        position: relative;
        vertical-align: middle;

        .icon {
            position: relative;
        }

        .icon-better-business-bureaua {
            font-size: 80px;
        }

        .icon-norton-secure-seal-white {
            font-size: 72px;
        }

        .icon-secure-website-white {
			font-size: 62px;
			height: 60px;
            vertical-align: super;
        }

        &:last-of-type {
            margin-right: 0;
        }

        @include breakpoint($sm) {
            margin-right: 28px;

            .icon-better-business-bureaua {
                font-size: 86px;
            }

            .icon-norton-secure-seal-white {
                font-size: 67px;
            }
        }
    }
}

.content__form {

    input {
        @include border-radius(0);
        color: #9B9B9B;
        font-family: $font-regular;
        font-style: italic;
        font-size: 16px;
        line-height: 40px;
        padding: 10px 20px;
        border: 1px solid #979797;
        background-color: $color-white;
        height: 60px;
        box-shadow: inset 1px 1px 3px 0 rgba(0,0,0,.50);

        &:focus {
            outline: 0px;
            color: $color-gray;
            -webkit-appearance: none;
            font-family: $font-regular-semi-bold;
            font-style: inherit;
            font-size: 16px;
            box-shadow: none;
            border: 2px solid #2AABF7;
        }
    }

    .code-error {
        font-family: $font-regular;
        font-style: italic;
        padding: 30px 0px 6px 0px;
        font-size: 16px;
        color: $color-error;

        &:after {
            content: "-";
            color: transparent;
            display: table;
            margin-bottom: -16px;
        }
    }

    .has-error {
        label {
            display: block;
        }

        input {
            border: 2px solid $color-error;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            background: #ffe5e5;
        }

        span {
            img {
                background-color: $color-white;
                @include border-radius(50%);
                visibility: visible;
                height: 16px;
                margin-top: -3px;
                margin-right: 4px;
            }
        }
    }

    .has-success {
        input {
            @include border-radius(0);
            color: #9B9B9B;
            font-family: $font-regular;
            font-style: italic;
            font-size: 16px;
            border: 1px solid #979797;
            background-color: $color-white;
            box-shadow: inset 1px 1px 3px 0 rgba(0,0,0,.50);

            &:focus {
                outline: 0px;
                color: $color-gray;
                -webkit-appearance: none;
                font-family: $font-regular-semi-bold;
                font-style: inherit;
                font-size: 16px;
                box-shadow: none;
                border: 2px solid #2AABF7;
            }
        }


        span {
            img {
                visibility: hidden;
                height: 0px;
                margin-top: 3px;
                padding-right: 0px;
            }
        }
    }
}

