﻿.component-hero--container {
  background: $color-light-blue-secondary;
  position: relative;
}

@mixin component-hero--container--min-sm() {
  .component-hero--container {
    background-color: white;
    z-index: 2;
    padding-bottom: 0;
  }
}

@mixin component-hero--container--min-md() {
  .component-hero--container {
    background-color: #f8faf9;
  }
}

.component-hero--large {
  position: relative;
  text-align: center;

  .hero__content {
    padding-top: 20px;
  }

  .background {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100%;
  }

  .content__title {
    color: $color-text-primary;
    font: 700 36px/1.3 $font-heading-bold;
    margin: 0 12%;
    font-size: 32px;

    &>span {
      display: inline-block;
      position: relative;

      &:after {
        background: url('/assets/images/Group.svg');
        background-repeat: no-repeat;
        background-size: 100%;
        top: 34px;
        content: '';
        display: block;
        height: 55px;
        left: -8px;
        padding-bottom: 0;
        position: absolute;
        width: 106%;
      }

      &>u {
        text-decoration: none;
        position: relative;
        z-index: 2;
      }
    }

    sup {
      font-size: 55%;
      top: -0.7em;
    }
  }

  .content__text {
    color: $color-form-control-text;
    font-size: 16px;
    padding: 0 6%;
    margin: 20px 0;

    sup {
      font-size: 56%;
      top: -0.7em;
    }
  }
}

@mixin component-hero--large--min-sm() {
  .component-hero--large {
    padding: 90px 2.5% 120px;
    text-align: left;

    &.has-bg {
      background: {
        image: url('/assets/images/Hero-sm.jpg');
        position: center;
        repeat: no-repeat;
        size: cover;
      }
    }

    .container {
      margin-left: 0;
    }

    .hero__content {
      .content__form .btn {
        margin-left: 16px;
      }
    }

    .background {
      display: block;
    }

    .content__title {
      margin-left: 0;
      font-size: 37px;
      line-height: 42px;
      max-width: 500px;
      padding-right: 5%;

      &>span {
        &:after {
          left: 13px;
          top: 33px;
          width: 105%;
        }
      }
    }

    .content__text {
      font-size: 16px;
      margin: 20px 0 36px;
      max-width: 460px;
      padding: 0 10% 0 0;
    }
  }
}

@mixin component-hero--large--min-md() {
  .component-hero--large {
    .content__title {
      font-size: 42px;
      line-height: 48px;
      max-width: 600px;
      padding-right: 15%;

      &>span {
        &:after {
          left: 13px;
          top: 38px;
          width: 105%;
        }
      }
    }

    .content__text {
      max-width: 520px;
    }
  }
}

@mixin component-hero--large--min-lg() {
  .component-hero--large {
    .content__title {
      padding-right: 10%;
    }

    .content__text {
      max-width: 640px;
    }
  }
}

@mixin component-hero--large--min-xg() {
  .component-hero--large {
    &.has-bg {
      background: {
        image: url('/assets/images/Hero-xlg.jpg');
        position: center;
        repeat: no-repeat;
        size: cover;
      }
    }

    .background {
      background-size: auto;
    }

    .content__title {
      padding-right: 0;
    }

    .container {
      margin-left: auto;
    }
  }
}

.component-hero-footer {
  background-color: $color-purple-primary;
  box-shadow: 3px 5px 7px 0 rgba(38, 17, 64, 0.2);
  color: $color-white;
  margin-top: 40px;
  padding: 20px 0;
  position: relative;

  .__content {
    margin: 0 auto;

    .container {}
  }

  .content__text-wrap {
    font-size: 18px;
    margin: 0 auto;
    position: relative;
    text-align: left;
    width: 290px;

    sup {
      font-size: 56%;
      top: -0.7em;
    }
  }

  .content__brands {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: -25px;
    align-items: center;

    .hero-logo {
      flex: 50%;
      padding-bottom: 25px;

      img {
        max-width: 100%;
        width: auto;
      }
    }

    a {
      color: $color-white;
      text-decoration: none;
    }
  }
}

@mixin component-hero-footer--min-xs() {
  .component-hero-footer {
    .__content {
      .container {
        padding-right: 0;
        padding-left: 0;
      }
    }

    .content__text-wrap {
      margin: auto auto;
      text-align: center;
    }

    .content__brands {
      text-align: center;
      margin: 20px auto -25px;
      max-width: 320px;
      align-items: center;

      .logo--wellcare {
        .icon-wellcare-reverse {
          font-size: 24px;
        }
      }

      .logo--moo {
        padding-bottom: 25px;

        .icon-moo-reverse {
          font-size: 24px;
        }
      }

      .logo--aetna {
        .icon-aetna-reverse {
          font-size: 19px;
        }
      }

      .logo--humana {
        .icon-humana-reverse {
          font-size: 18px;
        }
      }

      .logo--anthembcbs {
        padding-top: 25px;
        width: 100%;

        .icon-anthembcbs-reverse {
          font-size: 20px;
        }
      }
    }
  }
}


@mixin component-hero-footer--min-sm() {
  .component-hero-footer {
    background-color: $color-light-blue-secondary;
    bottom: -80px;
    left: 0;
    max-width: 81%; //77%;
    padding: 0;
    position: absolute;

    .__content {
      background-color: $color-purple-primary;
      padding: 30px 0 20px 20px;
      position: relative;

      &:after {
        content: '';
        background-color: $color-purple-primary;
        box-shadow: 8px 0px 20px -10px rgba($color-black, 0.75);
        display: block;
        height: 100%;
        //right: -23px;
        position: absolute;
        //width: 50px;
        right: -30px;
        width: 70px;
        top: 0;
        transform: skew(-20deg);
        -webkit-transform: skew(-20deg);
        z-index: 1;
      }
    }

    .content__text-wrap {
      font-size: 16px;
      z-index: 2;
      text-align: left;
      width: auto;
    }

    .content__brands {
      text-align: left;
      margin: 15px 0 0 0;
      max-width: 620px;
      position: relative;
      z-index: 2;
      flex-wrap: inherit;
      justify-content: flex-start;

      .hero-logo {
        flex: inherit;
        padding: 0 10px 0;

        img {
          max-width: 100%;
          width: auto;
        }
      }

      .logo--wellcare {
        .icon-wellcare-reverse {
          font-size: 20px;
        }
      }

      .logo--moo {
        .icon-moo-reverse {
          font-size: 23px;
        }
      }

      .logo--aetna {
        .icon-aetna-reverse {
          font-size: 17px;
        }
      }

      .logo--humana {
        .icon-humana-reverse {
          font-size: 18px;
        }
      }

      .logo--anthembcbs {
        .icon-anthembcbs-reverse {
          font-size: 18px;
        }
      }
    }
  }
}

@mixin component-hero-footer--min-lg() {
  .component-hero-footer {
    .content__brands {
      text-align: left;
      margin: 15px 0 0 0;
      max-width: 100%;
      position: relative;
      z-index: 2;
    }
  }
}


@mixin component-hero-footer--min-xg() {}

@mixin component-hero-footer--min-xg-alt() {
  .component-hero-footer {
    width: 87.9%;
    max-width: 87.9%;
    bottom: -50px;

    .content__text-wrap {
      float: left;
      font-size: 18px;
      margin-left: 2%;
      width: 370px;
      text-align: left;

      @include breakpoint(1240px) {
        margin-left: 3%;
      }

      @include breakpoint(1440px) {
        margin-left: 10%;
      }

      @include breakpoint(1680px) {
        margin-left: 14%;
      }
    }

    .content__brands {
      margin-top: 10px;
      position: absolute;
      right: 0;

      .logo--wellcare {
        .icon-wellcare-reverse {
          font-size: 24px;
        }
      }

      .logo--moo {
        .icon-moo-reverse {
          font-size: 24px;
        }
      }

      .logo--aetna {
        padding-top: 2px;

        .icon-aetna-reverse {
          font-size: 18px;
        }
      }

      .logo--humana {
        .icon-humana-reverse {
          font-size: 20px;
        }
      }

      .logo--anthembcbs {
        .icon-anthembcbs-reverse {
          font-size: 20px;
        }
      }
    }
  }
}

@mixin component-hero-footer--min-xg() {}