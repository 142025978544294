﻿.thank-you {
    display: block;
    position: relative;
    width: 100%;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100%;

    @include breakpoint(415px) {
        margin-bottom: -132px;
        height: 803px;
        background-image: url("/assets/images/ty-hero-sm.jpg");
    }

    @include breakpoint(440px) {
        margin-bottom: -112px;
        height: 504px;
        background-image: url("/assets/images/ty-hero-sm_md.jpg");
    }

    @include breakpoint($sm) {
        margin-bottom: -118px;
        height: 504px;
        background-image: url("/assets/images/ty-hero-md.jpg");
    }

    @include breakpoint($md) {
        height: 504px;
        background-image: url("/assets/images/ty-hero-md.jpg");
    }

    @include breakpoint($lg) {
        height: 660px;
        background-image: url("/assets/images/ty-hero-lg.jpg");
    }

    @include breakpoint(1281px) {
        height: 660px;
        background-image: url("/assets/images/ty-hero-lg.jpg");
        background-size: auto;
        background-position: right;
        background-color: #b5d3d8;
    }

    .circular-agent {
        display: block;
        margin-left: auto;
        margin-right: auto;
        border-radius: 50%;
        margin-top: 21px;
        height: 130px;
        width: 130px;

        @include breakpoint(415px) {
            display: none;
        }
    }

    .container {
        padding-right: 43px;
        padding-left: 42px;

        @include breakpoint(415px) {
            padding-right: 15px;
            padding-left: 15px;
        }
    }

    .content {
        text-align: center;

        @include breakpoint(415px) {
            margin-left: 8px;
            text-align: left;
        }

        @include breakpoint($sm) {
            margin-left: 30px;
        }

        @include breakpoint($xg) {
            margin-left: 80px;
        }

        @include breakpoint(1480px) {
            margin-left: 255px;
        }

        @include breakpoint(1680px) {
            margin-left: 300px;
        }

        @include breakpoint(1880px) {
            margin-left: 355px;
        }
    }

    h1 {
        color: #353535;
        font-family: $font-heading-bold;
        font-size: 32px;
        line-height: 42px;
        margin-top: 37px;

        @include breakpoint($xs) {
            max-width: 310px;
            margin-bottom: 18px;
            margin-top: 22px;
        }

        @include breakpoint(526px) {
            max-width: 350px;
            margin-bottom: 18px;
            margin-top: 37px;
        }

        @include breakpoint($sm) {
            max-width: 540px;
            font-size: 42px;
            line-height: 52px;
            margin-top: 55px;
            margin-bottom: 26px;
            margin-top: 33px;
        }

        @include breakpoint($md) {
            max-width: 410px;
            margin-top: 100px;
        }

        @include breakpoint($lg) {
            max-width: 411px;
        }

        @include breakpoint($xg) {
            max-width: 500px;
        }
    }

    p {
        max-width: 540px;
        color: $color-text-primary;
        font-size: 16px;
        line-height: 27px;

        @include breakpoint($xs) {
            max-width: 290px;
            margin-bottom: 18px;
        }

        @include breakpoint($sm) {
            max-width: 50%;
        }

        @include breakpoint($lg) {
            max-width: 44%;
        }

        @include breakpoint($xg) {
            max-width: 40%;
        }
    }


    .phone {
        color: $color-light-blue;
        font-family: $font-regular-bold;
        font-size: 40px;
        font-weight: bold;
        line-height: 55px;
        margin-bottom: -4px;
        margin-top: -11px;

        a {
            color: $color-light-blue;

            &:hover,
            &:visited,
            &:active,
            &:focus {
                text-decoration: none;
            }
        }
    }

    .tty {
        color: $color-text-secondary;
        font-family: $font-regular-light;
        font-size: 40px;
        font-weight: 300;
        line-height: 55px;
    }

    .tnf-submit {
        display: none;

        @include breakpoint($sm) {
            display: block;
        }
    }

    .tnf-call-mobile {
        display: block;

        @include breakpoint($sm) {
            display: none;
        }
    }

    .btn.btn-primary {
        margin-top: 0;

        @include breakpoint($xs) {
            margin-top: -8px;
        }


        @include breakpoint($sm) {
            margin-top: 38px;
        }

        span {
            content: url(/assets/images/phone.svg);
        }
    }

    .btn span {
        margin-left: 0;
    }
}
