﻿@use '../generic/forms';

.skipahead {
    text-align: center;
    color: $color-text-secondary;
    font-family: $font-regular-bold;
    font-size: 14px;
    margin: 37px 0 0;

    a {
        color: $color-text-secondary;
        padding-bottom: 0;

        span {
            display: inline-block;
            font-size: 16px;
            @extend .icon;
            @extend .icon-right-arrow;
            vertical-align: middle;
            position: relative;
            margin-left: 6px;
            top: -2px;
        }

        span::before {
            color: $color-text-secondary;
        }

        &:hover,
        &:visited,
        &:active,
        &:focus {
            text-decoration: none;
        }
    }
}

.dripform {
    @include forms.honeypot-field;

    margin: 35px auto 197px;
    color: $color-gray;
    max-width: 294px;

    .pageWrapContainer {
        position: relative;
        overflow: hidden;
        height: auto;
    }

    .pageWrap {
        position: relative;
        list-style: none;
        display: flex;
        flex-flow: row;
        align-items: flex-start;
        margin: 0;
        padding: 0;
        transition: transform 0.4s ease-in-out; /*cubic-bezier(.27,.67,.9,1);*/
    }

    .page {
        display: inline-block;
        margin: 0;
        position: relative;
        vertical-align: top;
        flex: 0 0 100%;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
        height: 0;
        /* page states: .currentPage, .nextPage, .prevPage */
        &.currentPage {
            opacity: 1;
            height: auto;

            &.webkit-dripfix {
                transform: translateZ(0);
            }
        }
    }

    .step-title {
        color: $color-l-gray;
        font-size: 32px;
        line-height: 42px;
        font-family: $font-heading-bold;
        text-align: center;
    }

    .step-subtitle {
        color: $color-text-secondary;
        font-size: 16px;
        line-height: 26px;
        font-family: $font-regular;
        text-align: center;
    }

    .fieldset {
        padding-top: 18px;
    }

    .form-group {
        margin: 0 auto;
        vertical-align: top;
        @include clearfix();
        //max-width: 290px;
        &:not(:first-of-type) {
            margin-top: 25px;
        }

        .field-validation-valid {
            display: none;
        }

        .input-validation-error {
            font-style: italic;
            border: 2px solid $color-error;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            background: $color-pink;
            display: block;
            margin-bottom: 6px;
        }

        .field-validation-error {
            color: red;
            font-style: italic;
            margin-left: 22px;
            margin-bottom: 5px;
            display: block;

            &:before {
                content: url('/assets/images/error.svg');
                //background: url('/assets/images/error.svg'); /*url of image*/
                transform: scale(.5);
                height: 16px; /*height of image*/
                width: 33px; /*width of image*/
                position: absolute;
                margin-left: -30px;
            }
        }

        &.form-group-zipcode {
            .error_DripForm {
                margin-left: 19px;
                padding-left: 22px;
                margin-bottom: 5px;
            }

            label {
                display: block;
                margin-left: 19px;
            }

            input {
                max-width: 255px;
                margin-left: 19px;
            }
        }
    }

    label {
        font-size: 18px;
        font-weight: 600;
        line-height: 45px;
    }

    input {
        width: 100%;
    }

    .form-controls {
        text-align: center;
        margin-top: 30px;
    }

    .btn-link {
        box-shadow: none;
        display: block;
        max-width: 310px;
        margin: 25px auto 0;
        font-size: 16px;
        font-family: $font-regular-bold;
        height: auto;
        padding: 5px;
        color: $color-purple-primary;
        text-decoration: underline;

        span {
            margin-left: 0;
            margin-right: 5px;
            display: inline-block;
            @extend .icon;
            @extend .icon-left-arrow;
            vertical-align: middle;
            position: relative;
        }

        span::before {
            color: $color-purple-primary;
        }
    }

    .df-disclaimer {
        font-size: 14px;
        text-align: center;
        line-height: 21px;
        margin-top: 51px;
    }

    .progress {
        height: 5px;
        width: 150px;
        @include border-radius(10px);
        background-color: #d5d5d5;
        margin: 0 auto;

        .progress-bar {
            background-color: $progress-bar-color;
            height: 100%;
            @include border-radius(5px);
            width: 0;
        }
    }
}

@mixin dripform--min-sm() {
    .skipahead {
        text-align: right;
        margin: 30px 15px 0 0;
        font-size: 16px;

        a {
            span {
                font-size: 17px;
                margin-left: 4px;
                top: -2px;
            }
        }
    }

    .dripform {
        margin: 50px auto 114px;
        max-width: none;

        .step-title {
            font-size: 37px;
            line-height: 42px;
        }

        .df-disclaimer {
            font-size: 16px;
        }

        .fieldset {
            padding-top: 34px;
        }

        .form-group {
            max-width: 443px;
            margin: 0 auto;

            &.form-group-zipcode {
                label, input, .error_DripForm {
                    display: block;
                    max-width: 255px;
                    margin: 0 auto;
                }

                .error_DripForm {
                    margin-bottom: 5px;
                }
            }
        }

        .form-controls {
            margin-top: 39px;
        }

        .btn-link {
            span {
                margin-right: 10px;
            }
        }
    }
}

@mixin dripform--min-md() {
    .dripform {
        .step-title {
            font-size: 42px;
            line-height: 52px;
        }
    }
}